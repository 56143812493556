<template>
  <div v-if="pageContent">
    <button class="close main-close-btn btn btn-link btn-sm" v-if="!panels.infoWrapper"
            @click="$router.push({ name: 'Start' })">
      <img src="@/assets/images/returnHome.svg" alt="">
    </button>
    <div class="bg-white app-inner app-inner-intro8">
      <div class="mx-md-7 mb-8 py-8 pr-8 mt-5">
        <div>
          <h1 class="page-title">{{ pageContent.title }}</h1>
        </div>
        <div class="mb-7" v-html="pageContent.subtitle"></div>
        <div class="html-rich-content" v-html="pageContent.content"></div>
      </div>
    </div>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'

export default {
  name: 'StaticPage',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  data () {
    return {
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false
      },
      showInfoBox: false,
      pageContent: null,
      infoBoxData: null
    }
  },
  computed: {
    ...mapState('slides', ['page'])
  },
  components: {
    infoWrapper
  },
  methods: {
    ...mapActions('slides', ['searchPage', 'getPage', 'clearSlide']),
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    }
  },
  mounted () {
    let self = this
    const pagePaths = this.$route?.params?.pathMatch?.split('/')
    if (pagePaths && pagePaths.length === 1) {
      const pagePath = pagePaths[0]
      this.$nextTick(() => {
        this.searchPage(pagePath).then(resp => {
          if (resp && resp.data) {
            self.pageContent = resp.data
          } else {
            this.$router.push({ name: 'Start' })
          }
          self.done = true
        })
      })
    } else {
      this.$router.push({ name: 'Start' })
    }
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
<style>
#app{
  overflow-y: auto;
}
body{
  background-color: #fff;
}
</style>
